import Blocks from '@component/Blocks';
import PageHeader from '@component/PageHeader';

export type LayoutPageProps = {
  /**/
};

const LayoutPage = () => {
  // const view = useView();

  return (
    <>
      <PageHeader />
      <Blocks />
    </>
  );
};

export default LayoutPage;
